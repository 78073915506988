export const toggleRadio = (physicalUser, juridicalInputs, companyName, companyCode, vatCode) => {
  if (physicalUser.checked) {
    juridicalInputs.classList.add('hidden');
    companyName.removeAttribute('required');
    companyCode.removeAttribute('required');
    vatCode.removeAttribute('required');
  } else {
    juridicalInputs.classList.remove('hidden');
    companyName.setAttribute('required', 'required');
    companyCode.setAttribute('required', 'required');
    vatCode.setAttribute('required', 'required');
  }
};

const init = () => {
  const createSenderModal = document.querySelector('#createSenderModalWrapper');
  if (createSenderModal) {
    const physicalUser = document.querySelector('#shop_user_sender_type_0');
    const juridicalUser = document.querySelector('#shop_user_sender_type_1');
    const juridicalInputs = document.querySelector('.juridical-inputs');
    const companyName = document.querySelector('#shop_user_sender_company');
    const companyCode = document.querySelector('#shop_user_sender_companyCode');
    const vatCode = document.querySelector('#shop_user_sender_vatCode');

    physicalUser.checked = true;
    toggleRadio(physicalUser, juridicalInputs, companyName, companyCode, vatCode);

    physicalUser.addEventListener(
      'change',
      () => toggleRadio(physicalUser, juridicalInputs, companyName, companyCode, vatCode)
    );
    juridicalUser.addEventListener(
      'change',
      () => toggleRadio(physicalUser, juridicalInputs, companyName, companyCode, vatCode)
    );
  }
};

init();
