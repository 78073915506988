import { library, dom } from '@fortawesome/fontawesome-svg-core';

import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';

library.add(faChevronRight, faChevronLeft, faChevronUp, faChevronDown, faQuestionCircle, faCheck, faPlus, faTimes);
dom.watch();
