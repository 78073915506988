export default function increasingCounters() {
  if (!document.querySelector("[increasing-counter]")) {
    return null;
  }

  const counters = document.querySelectorAll("[increasing-counter]");

  counters.forEach((counter) => {
    const value = Number(counter.getAttribute("increasing-counter"));

    if (value === 0) {
        counter.innerText = "0.00";
        return null;
    }

    const interval = setInterval(timer, 50);
    let iterations = 0;

    function timer() {
      counter.innerText = iterations;
      iterations++;
      if (iterations === value) {
        timerStopFunction();
      }
    }

    function timerStopFunction() {
      clearInterval(interval);
    }
  });
}
