import { open, close } from './modal';

const toggleNavigationModal = () => {
  const openModalButton = document.querySelector('[data-js-open-nav-modal]');
  const closeModalButton = document.querySelectorAll('[data-js-close-nav-modal]');
  const modalWrap = document.querySelector('.header-wrap');

  open(openModalButton, modalWrap);
  close(closeModalButton, modalWrap);
};

toggleNavigationModal();
