const initTogglingHiddenContainer = () => {
  const toggleCheckboxContainer = document.querySelector('[data-toggle-hidden-form]');
  
  if (!toggleCheckboxContainer) return;
  
  const toggleContainerName = toggleCheckboxContainer.dataset.toggleHiddenForm;
  const toggleCheckbox = toggleCheckboxContainer.querySelector('input');
  const toggleContainer = document.querySelector(`[data-hidden-form="${toggleContainerName}"]`);
  
  if (!toggleContainerName || !toggleCheckbox || !toggleContainer) return;
  
  let requiredFields = [];
  
  const hideForm = (requiredFields) => {
    toggleContainer.classList.add('display-none');
    requiredFields.forEach((field) => field.removeAttribute('required'));
  };
  
  const showForm = (requiredFields) => {
    toggleContainer.classList.remove('display-none');
    requiredFields.forEach((field) => field.setAttribute('required', 'required'));
  };
  
  const toggleFormVisibilityChange = (checked) => {
    if (checked) {
      showForm(requiredFields);
    } else {
      requiredFields = toggleContainer.querySelectorAll('[required="required"');
      
      hideForm(requiredFields);
    }
  };
  
  toggleFormVisibilityChange(!!toggleCheckbox.checked);
  
  toggleCheckbox.addEventListener('change', (e) => {
    const checked = e.target.checked;
  
    toggleFormVisibilityChange(checked);
  });
};

initTogglingHiddenContainer();
