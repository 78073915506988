const toggleCustomSizes = () => {
  const radiosWrap = document.querySelector('.js-sizes-radios-wrap')

  if (radiosWrap) {
    const sizeRadios = radiosWrap.querySelectorAll('input[type="radio"]')

    sizeRadios.forEach((radio) => {
      radio.addEventListener('click', () => toggleClass(radio))
    })
  }
}

const toggleClass = (radio) => {
  const customSizesWrap = document.querySelector('[data-js-custom-sizes-inputs]');

  if (radio.value === 'custom' && radio.checked) {
    customSizesWrap.classList.add('is-visible');

    return;
  }

  customSizesWrap.classList.remove('is-visible');
}

toggleCustomSizes();
