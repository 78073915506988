import "./customSizesToggle";
import "./toggleCodInputs";
import "./Modal/navigation";
import "./Modal/newUserForm";
import initAutoComplete from "./autoComplete";
import orderFlow from "./orderFlow";
import "./userSender";
import "./userRadioRadio";
import "./Modal/firstVisit";
import "./courier";
import "./checkoutScroll";
import "./toggleHiddenForm";
import "./billingAddressForm";
import increasingCounters from "./increasingCounters";

initAutoComplete();
orderFlow();
increasingCounters();
