function radioInputSelected(containerSelector) {
  if (!document.querySelector(containerSelector)) {
    return null;
  }

  const sizeRadioGroup = document.querySelector(containerSelector);

  const radioInputs = sizeRadioGroup.querySelectorAll("input[type=radio]");
  const uncheckedRadioButtons = [];

  radioInputs.forEach((radioInput) => {
    if (!radioInput.checked) {
      uncheckedRadioButtons.push(radioInput);
    }
  });

  if (uncheckedRadioButtons.length === radioInputs.length) {
    return false;
  } else {
    return true;
  }
}

function homepageForm() {
  const submitBtn = document.querySelector(".sizes-form-submit-btn button");
  const formWrapper = document.querySelector(".sizes-form-wrap");

  const validateRadioInputs = () => {
    const radioGroupWrapperSelector = ".js-sizes-radios-wrap";
    const validationShipmentSizeTranslation = formWrapper.getAttribute(
      "validation-shipment-size-translation"
    );
    const sizeRadioButtonSelected = radioInputSelected(
      radioGroupWrapperSelector
    );

    const removeOldNodes = () => {
      document
        .querySelectorAll("[size-radio-validation-msg]")
        .forEach((node) => {
          node.remove();
        });
    };

    removeOldNodes();

    document
      .querySelectorAll(radioGroupWrapperSelector)
      .forEach((radioInput) => {
        radioInput.addEventListener("click", () => {
          removeOldNodes();
        });
      });

    if (!sizeRadioButtonSelected) {
      const validationPlacement = document.querySelector(
        radioGroupWrapperSelector
      );
      const node = document.createElement("p");
      node.classList.add("validation-text");
      node.setAttribute("size-radio-validation-msg", true);
      const textnode = document.createTextNode(
        validationShipmentSizeTranslation
      );
      node.appendChild(textnode);
      validationPlacement.appendChild(node);

      return false;
    } else {
      return true;
    }
  };

  const checkForTyping = () => {
    const inputs = document.querySelectorAll("[autocomplete-component]");
    inputs.forEach((input) => {
      input.onkeyup = () => {
        input.setAttribute("autocomplete-was-typed", true);
      };
    });
  };

  checkForTyping();

  const validateLocationInputs = () => {
    const validationPickLocationTranslation = formWrapper.getAttribute(
      "validation-pick-location-translation"
    );
    const autocompleteComponents = document.querySelectorAll(
      "[autocomplete-component]"
    );

    const removeOldNodes = () => {
      document
        .querySelectorAll("[autoselect-validation-msg]")
        .forEach((node) => {
          node.remove();
        });
    };

    removeOldNodes();

    let validationResult = false;

    autocompleteComponents.forEach((component) => {
      const inputValueSelected = component.value !== "";
      const autocompleteValueSelected = component.getAttribute(
        "autocomplete-value-selected"
      );
      const autocompleteWasTyped = component.getAttribute(
        "autocomplete-was-typed"
      );

      if (
        (inputValueSelected === true && !autocompleteWasTyped) ||
        autocompleteValueSelected === "true"
      ) {
        validationResult = true;
      } else {
        const node = document.createElement("p");
        node.classList.add("validation-text");
        node.setAttribute(`autoselect-validation-msg`, true);
        const textnode = document.createTextNode(
          validationPickLocationTranslation
        );
        node.appendChild(textnode);
        component.parentNode.parentNode.appendChild(node);

        validationResult = false;
      }
    });

    return validationResult;
  };

  submitBtn.addEventListener("click", (e) => {
    e.preventDefault();

    const locationInputsValid = validateLocationInputs();
    const radioInputsValid = validateRadioInputs();

    if (locationInputsValid && radioInputsValid) {
      document.querySelector(".sizes-form").submit();
    }
  });
}

function deliveryMethodForm() {
  const form = document.querySelector("[name='checkout_shipping']");
  const deliveryMethodRadios = form.querySelectorAll("[data-js-shipping-types] input[type=radio]");
  let selectedDeliveryMethods = 0;

  deliveryMethodRadios.forEach((radio) => {
    if (radio.checked) {
      selectedDeliveryMethods ++;
    }
  });

  if (selectedDeliveryMethods === 0) {
    deliveryMethodRadios[0].checked = true;
  }

  form.querySelectorAll("input").forEach((input) => {
    input.addEventListener("change", () => {
      const id = input.id;
      const name = input.name;

      if (
        id === "checkout_shipping_customSize_depth" ||
        id === "checkout_shipping_customSize_width" ||
        id === "checkout_shipping_customSize_height" ||
        id === "checkout_shipping_customSize_weight"
      ) {
        const depth = document.getElementById(
          "checkout_shipping_customSize_depth"
        ).value;
        const width = document.getElementById(
          "checkout_shipping_customSize_width"
        ).value;
        const height = document.getElementById(
          "checkout_shipping_customSize_height"
        ).value;
        const weight = document.getElementById(
          "checkout_shipping_customSize_weight"
        ).value;

        if (depth === "" || width === "" || height === "" || weight === "") {
          return null;
        }
      }

      if (
        name === "checkout_shipping[isCod]" ||
        name === "checkout_shipping[couriers]" ||
        id === "checkout_shipping_parcelSize_3" ||
        name === "checkout_shipping[agreeWithCod]" ||
        id === "checkout_shipping_codPrice" ||
        id === "checkout_shipping_bankAccount"
      ) {
        return null;
      }

      localStorage.setItem(
        "scrollPosition",
        document.querySelector(".app-container").scrollTop
      );
      form.submit();
    });
  });
}

function orderInformationForm() {
  const form = document.querySelector("[name='checkout_customer']");

  const btn = document.querySelector("[toggle-full-sender-form]");

  const additionalSenderInfoFields = document.querySelectorAll(
    ".toggle-sender-info"
  );

  btn.onclick = () => {
    if (btn.classList.contains("active")) {
      btn.classList.remove("active");
      additionalSenderInfoFields.forEach((field) => {
        field.classList.remove("toggle-sender-info--active");
      });
    } else {
      btn.classList.add("active");
      additionalSenderInfoFields.forEach((field) => {
        field.classList.add("toggle-sender-info--active");
        const id = field.querySelector("input, select").id;
        if (id === 'checkout_customer_senderAddress_countryCode'
          || id === 'auto-complete-checkout_customer_senderAddress_city') {
          return;
        }
        field.querySelector("input, select").value = "";
      });
    }
  };
}

function paymentForm() {
  const form = document.querySelector(
    "[name='sylius_checkout_select_payment']"
  );
  const btn = document.querySelector(".checkout-button button");
  const paymentRadioInputs = form.querySelectorAll('input[type=radio]')
  const title = document.querySelector('[error-msg="select-payement-title"]');
  const errorMsg = document.querySelector('[error-msg="select-payement-method"]');
  const errorMsgActiveClass = 'error-msg--active';
  const titleErrorClass = 'text-danger';
  let paymentMethodSelected = false;

  paymentRadioInputs.forEach(input => {
    input.checked = false;
    input.onclick = () => {
      errorMsg.classList.remove(errorMsgActiveClass);
      title.classList.remove(titleErrorClass);
    }
  });

  const checkIfSelected = () => {
    paymentRadioInputs.forEach(input => {
      if (input.checked) {
        paymentMethodSelected = true;
      }
    });
  }

  btn.onclick = (e) => {
    e.preventDefault();
    checkIfSelected();
    if (paymentMethodSelected === true) {
      form.submit();
    } else {
      errorMsg.classList.add(errorMsgActiveClass);
      title.classList.add(titleErrorClass);
    }
  };
}

export default function orderFlow() {
  if (document.querySelector(".homepage-form-wrap")) {
    homepageForm();
  }
  if (document.querySelector("[name='checkout_shipping']")) {
    deliveryMethodForm();
  }
  if (document.querySelector("[name='checkout_customer']")) {
    orderInformationForm();
  }
  if (document.querySelector("[name='sylius_checkout_select_payment']")) {
    paymentForm();
  }
}
