const onOpen = (modalContainingEl) => {
  const app = document.querySelector('#app');
  const modalContainer = modalContainingEl.querySelector('.modal-container');
  const modal = modalContainer.querySelector('.modal');

  modalContainer.classList.add('is-opened');
  modal.classList.add('is-opened');
  app.classList.add('no-scroll');
};

const onClose = (modalContainingEl, cleanup) => {
  const app = document.querySelector('#app');
  const modalContainer = modalContainingEl.querySelector('.modal-container');
  const modal = modalContainer.querySelector('.modal');

  const forceOpen = document.querySelector('.force-open');
  if (forceOpen) {
    forceOpen.classList.remove('force-open');
  }

  if (cleanup) cleanup();

  modalContainer.classList.remove('is-opened');
  modal.classList.remove('is-opened');
  app.classList.remove('no-scroll');
};

export const open = (button, modalContainingEl) => {
  if (button) button.addEventListener('click', () => onOpen(modalContainingEl));
};

export const close = (buttons, modalContainingEl, cleanup) => {
  for (let i = 0; i < buttons.length; ++i) {
    buttons[i].addEventListener('click', () => onClose(modalContainingEl, cleanup));
  }
};
