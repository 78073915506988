import { open, close } from './modal';

const cleanup = () => {
  const wrapperSelector = '#createSenderModalWrapper';
  const inputs = [
    '#shop_user_sender_company',
    '#shop_user_sender_companyCode',
    '#shop_user_sender_vatCode',
    '#shop_user_sender_firstName',
    '#shop_user_sender_lastName',
    '#shop_user_sender_street',
    '#shop_user_sender_city',
    '#shop_user_sender_postCode',
    '#shop_user_sender_phoneNumber',
    '#shop_user_sender_email',
    '#shop_user_sender_countryCode',
  ];

  const physicalRadioSelector = document.querySelector(`${wrapperSelector} #shop_user_sender_type_0`);
  physicalRadioSelector.click();

  inputs.forEach(input => {
    const inputSelector = document.querySelector(`${wrapperSelector} ${input}`);
    if (inputSelector) inputSelector.value = '';
  });
};

const toggleNewUserFormModal = () => {
  const openModalButton = document.querySelector('[data-js-open-user-form-modal]');
  const closeModalButton = document.querySelectorAll('[data-js-close-user-form-modal]');
  const modalWrap = document.querySelector('.checkout')
    || document.querySelector('#createSenderModalWrapper');

  open(openModalButton, modalWrap);
  close(closeModalButton, modalWrap, cleanup);
};

toggleNewUserFormModal();
