import { open, close } from './Modal/modal';

const init = () => {
  const fetchSender = async (url, body) => {
    await fetch(url, body)
      .then(response => response.json())
      .then((data) =>  document.querySelector('#editSenderModal').innerHTML = data.content);

    const openModalButton = document.querySelector('[data-js-open-user-edit-form-modal]');
    const closeModalButton = document.querySelectorAll('[data-js-close-user-edit-form-modal]');
    const modalWrap = document.querySelector('#editSenderModalWrapper');
    open(openModalButton, modalWrap);
    close(closeModalButton, modalWrap);

    const editForm = document.querySelector('#editSenderModalWrapper [name="shop_user_sender"]');

    editForm.addEventListener('submit', async (event) => {
      event.preventDefault();
      const form = document.querySelector('#editSenderModalWrapper [name="shop_user_sender"]');
      const editFormData = new FormData(form);
      await fetchSender(url, {
        method: 'POST',
        body: editFormData,
      });
      if (!document.querySelector('#editSenderModalWrapper .has-error')) {
        location.reload();
        document.querySelector('[data-js-close-user-edit-form-modal]').click();
      }
    });
  };

  const senderAddress = document.querySelector('#shop_user_sender_address_senderAddresses');
  const deleteSenderAddressBtn = document.querySelector('#deleteSenderAddress');

  if (senderAddress && deleteSenderAddressBtn) {
    const deleteSenderAddressBtn = document.querySelector('#deleteSenderAddress');
    deleteSenderAddressBtn.setAttribute('action', `/${senderAddress.firstChild.value}`);
    fetchSender(`/en_US/account/editSenderAddress/${senderAddress.firstChild.value}`);

    senderAddress.addEventListener('change', (event) => {
      document.querySelector('#deleteSenderAddress').setAttribute('action', `/${event.target.value}`);
      fetchSender(`/en_US/account/editSenderAddress/${event.target.value}`);
    });
  }
};

init();
