import { close } from './modal';

const toggleNewUserFormModal = () => {
  const closeModalButton = document.querySelectorAll('[data-js-close-first-visit-modal]');
  const modalWrap = document.querySelector('#firstVisitModalWrapper');
  if (closeModalButton && modalWrap) {
    const shouldFirstVisitModalOpen = localStorage.getItem('shouldFirstVisitModalOpen');
    localStorage.setItem('shouldFirstVisitModalOpen', 'yes');
    if (!shouldFirstVisitModalOpen) modalWrap.classList.add('force-open');
    close(closeModalButton, modalWrap);
  }
};

toggleNewUserFormModal();
