import { toggleRadio } from './userRadioRadio';

const init = () => {
  const form = document.querySelector('[data-hidden-form="billing-address"]');
  
  if (form) {
    const physicalUser = document.querySelector('#checkout_customer_billingAddress_type_0');
    const juridicalUser = document.querySelector('#checkout_customer_billingAddress_type_1');
    const juridicalInputs = form.querySelector('.juridical-inputs');
    const companyName = document.querySelector('#checkout_customer_billingAddress_company');
    const companyCode = document.querySelector('#checkout_customer_billingAddress_companyCode');
    const vatCode = document.querySelector('#checkout_customer_billingAddress_vatCode');
    
    toggleRadio(physicalUser, juridicalInputs, companyName, companyCode, vatCode);
    
    physicalUser.addEventListener(
      'change',
      () => toggleRadio(physicalUser, juridicalInputs, companyName, companyCode, vatCode)
    );
    juridicalUser.addEventListener(
      'change',
      () => toggleRadio(physicalUser, juridicalInputs, companyName, companyCode, vatCode)
    );
  }
};

init();
