import autoComplete from "@tarekraafat/autocomplete.js/dist/js/autoComplete";
import { stripHtml } from "../Utils";

export default function initAutoComplete() {
  const autoCompleteComponentAttr = "[autocomplete-component]";

  if (!document.querySelector(autoCompleteComponentAttr)) {
    return null;
  }

  document
    .querySelectorAll(autoCompleteComponentAttr)
    .forEach((autocompleteComponent) => {
      const autocompleteComponentId = autocompleteComponent.getAttribute(
        "autocomplete-component"
      );
      const noResultTranslation = autocompleteComponent.getAttribute(
        "autocomplete-translate-no-result"
      );
      const loadingTranslation = autocompleteComponent.getAttribute(
        "autocomplete-translate-loading"
      );
      const cityCountryTranslation = autocompleteComponent.getAttribute(
        "autocomplete-translate-city-country"
      );
      const cityTranslation = autocompleteComponent.getAttribute(
        "autocomplete-translate-city"
      );
      const cityOnly = autocompleteComponent.getAttribute(
        "autocomplete-city-only"
      );

      const placeholder =
        cityOnly === "autocomplete-city-only"
          ? cityTranslation
          : cityCountryTranslation;

      const AUTOCOMPLETION_URL =
        "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json";
      const APIKEY = "3IHDWSx_QWHRpET_q0p8xK-9mjvx7WgTvl5nNSF0KPQ";
      let inputVal = "";

      autocompleteComponent.addEventListener("input", (event) => {
        inputVal = event.target.value;

        autocompleteComponent.setAttribute(
          "autocomplete-value-selected",
          false
        );
      });

      function getParams() {
        const params =
          "?" +
          "query=" +
          encodeURIComponent(inputVal) +
          "&maxresults=20" +
          "&beginHighlight=" +
          encodeURIComponent("<b>") +
          "&endHighlight=" +
          encodeURIComponent("</b>") +
          "&apikey=" +
          APIKEY;

        return params;
      }

      new autoComplete({
        data: {
          src: async () => {
            // Loading placeholder text
            autocompleteComponent.setAttribute(
              "placeholder",
              loadingTranslation
            );
            // Fetch External Data Source
            const source = await fetch(AUTOCOMPLETION_URL + getParams());
            const res = await source.json();
            const suggestionsData = res.suggestions;
            const data = [];

            suggestionsData &&
              suggestionsData.map((dataItem) => {
                if (dataItem.matchLevel === "country") {
                  const {
                    address: { country },
                    countryCode,
                  } = dataItem;
                  const formatedDataItem = {
                    country,
                    countryCode,
                  };
                  data.push(formatedDataItem);
                }
              });

            // Post loading placeholder text
            autocompleteComponent.setAttribute("placeholder", placeholder);
            // Returns Fetched data

            return data;
          },
          key: ["country"],
          cache: false,
        },
        placeHolder: placeholder,
        selector: `#auto-complete-${autocompleteComponentId}`,
        threshold: 1,
        debounce: 200,
        searchEngine: (query, record) => {
          return record;
        },
        highlight: true,
        resultsList: {
          render: true,
          container: (source) => {
            source.setAttribute(
              "id",
              `autocomplete-list-${autocompleteComponentId}`
            );
            source.setAttribute("class", "autocomplete-list");
          },
          destination: autocompleteComponent,
          position: "afterend",
          element: "ul",
        },
        resultItem: {
          content: (data, source) => {
            const suggestionDisplay = `${stripHtml(data.match)}`;
            source.innerHTML = suggestionDisplay;
          },
          element: "li",
        },
        noResults: () => {
          const result = document.createElement("li");
          result.innerHTML = noResultTranslation;
          document
            .querySelector(`#autocomplete-list-${autocompleteComponentId}`)
            .appendChild(result);
        },
        onSelection: (feedback) => {

          const country = stripHtml(feedback.selection.value.country);
          const countryCode = stripHtml(feedback.selection.value.countryCode);

          let hiddenInput;

          if (autocompleteComponentId === "checkout_customer_sender_city") {
            hiddenInput = document.getElementById(
              "checkout_customer_sender_senderCityFromHidden"
            );
          } else if (
            autocompleteComponentId === "checkout_customer_recipient_city"
          ) {
            hiddenInput = document.getElementById(
              "checkout_customer_recipient_recipientCityFromHidden"
            );
          } else if (
            autocompleteComponentId === "checkout_customer_senderAddress_city"
          ) {
            hiddenInput = document.getElementById(
              "checkout_customer_senderAddress_senderCityFromHidden"
            );
          } else if (
            autocompleteComponentId === "checkout_customer_shippingAddress_city"
          ) {
            hiddenInput = document.getElementById(
              "checkout_customer_senderAddress_recipientCityFromHidden"
            );
          } else {
            hiddenInput = document.querySelector(
              `#${autocompleteComponentId}Hidden`
            );
          }

          hiddenInput.value = countryCode;

          autocompleteComponent.value = country;
          autocompleteComponent.setAttribute(
            "autocomplete-value-selected",
            true
          );
          autocompleteComponent.setAttribute("autocomplete-was-typed", false);
        },
      });
    });
}
