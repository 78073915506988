const onCheckboxChange = () => {
  const codCheckbox = document.querySelector('.js-toggle-cod')

  if (codCheckbox) {
    if (codCheckbox.checked) {
      codInputsWrap.classList.add('is-visible');

      return;
    }

    codCheckbox.addEventListener('change', () => {
      const codInputsWrap = document.querySelector('[data-js-cod-inputs]');

      codInputsWrap.classList.toggle('is-visible');
    })
  }
}

onCheckboxChange();
