const isRadioChecked = (couriers) => {
  let isChecked = false;
  couriers.forEach((courier) => {
    if (courier.checked) isChecked = true;
  });
  return isChecked;
};

const changeDeliveryPrice = (courier) => {

  const deliveryMethodsRow = document.querySelector('.delivery-methods-row');
  const prefix = courier.parentNode.parentNode.getAttribute('courier-prefix');

  if (deliveryMethodsRow) {
    const radio = deliveryMethodsRow.querySelector(`[data-courier-code-${courier.value}-${prefix}]`);
    const deliveryRadioContainer = document.querySelector(`[delivery-prefix='${prefix}']`);
    const selectedCourierPrice = radio.getAttribute(`data-courier-code-${courier.value}-${prefix}`);

    if (selectedCourierPrice) {
      const radioText = deliveryRadioContainer.querySelector('.radio-bubble__text');
      const radioExtraText = deliveryRadioContainer.querySelector('.radio-bubble__extra-text');

      radioText.innerHTML = selectedCourierPrice;

      radioExtraText.classList.add('is-hidden');
    }
  }
}

const init = () => {
  const submitButton = document.querySelector('#checkout_shipping_submit');
  const couriers = document.querySelectorAll('[name="checkout_shipping[couriers]"]');
  const couriersTitle = document.querySelector('#courier-title');
  const courierError = document.querySelector('#courier-error');

  if (submitButton && couriers && couriersTitle && courierError) {
    couriers.forEach((courier) => {

      courier.checked = false;

      courier.addEventListener('change', () => {

        couriersTitle.classList.remove('courier-header-error');
        courierError.classList.remove('visible');

        changeDeliveryPrice(courier);
      });
    });

    submitButton.addEventListener('click', (event) => {
      if (!isRadioChecked(couriers)) {
        event.preventDefault();
        couriersTitle.classList.add('courier-header-error');
        courierError.classList.add('visible');
      }
    });
  }
};

init();
