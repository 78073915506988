import '../media/logo.svg';
import '../media/logo.png';
import '../media/favicon.ico';
import '../media/check.svg';
import '../media/socials-fb.svg';
import '../media/email-icon.svg';
import '../media/tel-icon.svg';
import '../media/switch.svg';
import '../media/sent.svg';
import '../media/cod.svg';
import '../media/clients.svg';
import '../media/print.svg';
import '../media/print2.svg';
import '../media/edit.svg';
import '../media/chevron-down.svg';
import '../media/cancel.svg';
import '../media/parcel-icon.svg';
import '../media/shop-icon.svg';
import '../media/envelope-icon.svg';
import '../media/van-icon.svg';
import '../media/key-icon.svg';
import '../media/cake-icon.svg';
import '../media/marker-icon.svg';
import '../media/transparent-marker-icon.svg';
import '../media/transparent-info-icon.svg';
import '../media/transparent-clock-icon.svg';

import '../media/bg1.png';
import '../media/bg2.png';
import '../media/bg3.png';
import '../media/right-graphic.png';
import '../media/left-graphic.png';
import '../media/right-graphic-transparent.svg';
import '../media/left-graphic-transparent.svg';
import '../media/lpExpress.png';
import '../media/dpd.png';
import '../media/omniva.png';
import '../media/lp.png';
import '../media/swedbank.png';
